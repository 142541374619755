<template>
  <div class="background--modal" />
  <ion-header class="ion-no-border text-center">
    <ion-toolbar color="transparent">
      <ion-button
        slot="start"
        fill="clear"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="dismissModal()"
      >
        <img
          src="../../assets/images/arrow-go-back-line.svg"
        >
      </ion-button>
      <ion-title class="text--white text-18">
        {{ $t('Edit Automation') }}
      </ion-title>
      <ion-button
        slot="end"
        fill="clear"
        color="transparent"
        :disabled="!automation.if.length || !automation.then.length || !automation.automationName"
        size="large"
        class="close-modal-button"
        @click="saveAutomation()"
      >
        <ion-icon
          color="primary"
          size="large"
          :icon="checkmarkDoneOutline"
        />
      </ion-button>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding-start ion-padding-end ion-padding-bottom">
    <div v-if="selectedView === '1'">
      <div
        class="form--dark flex-1 ion-padding-bottom"
        :class="platform.includes('ios') ? 'form--dark-ios' : 'form--dark-md'"
      >
        <ion-item lines="none">
          <ion-label position="floating">
            {{ $t('Automation Name') }}
          </ion-label>
          <ion-input
            id="automationName"
            v-model="editAutomation.automationName"
            type="text"
            enterkeyhint="done"
            required
            @keydown.enter="closeKeyboard"
          />
        </ion-item>
      </div>
      <div class="text-left text--uppercase">
        <span class="text--bold text--primary">{{ $t('IF ANY') }}</span><span style="color: rgba(217, 225, 217, 0.4);">{{ $t(' of the conditions are satisfied') }}</span>
      </div>
      <div v-if="editAutomation.if.length">
        <div
          v-for="ifCondition in editAutomation.if"
          :key="ifCondition"
        >
          <ion-item
            lines="none"
            class="rounded-corners mt-8 h-65"
            @click="editCondition(ifCondition)"
          >
            <div slot="start">
              <i class="material-icons">{{ ifCondition.icon }}</i>
            </div>
            <div slot="start">
              <ion-label>
                <ion-text class="text-16 text--white">
                  {{ ifCondition.description }}
                </ion-text><br>
                <ion-text
                  class="text-12 text--gray"
                >
                  {{ ifCondition.description2 }}
                </ion-text>
              </ion-label>
            </div>
            <div slot="end">
              <i class="material-icons">chevron_right</i>
            </div>
          </ion-item>
        </div>
      </div>
      <div
        class="mt-30"
      >
        <div @click="automationConditionModal">
          <ion-icon
            class="add-icon rounded-corners-20"
            :icon="addOutline"
          />
        </div>
        <ion-label style="color: rgba(217, 225, 217, 0.4);">
          {{ $t('Add condition') }}
        </ion-label>
      </div>
      <div class="text-left mt-30 text--uppercase">
        <span class="text--bold text--primary">{{ $t('THEN') }}</span> <span style="color: rgba(217, 225, 217, 0.4);">{{ $t('execute the following actions') }}</span>
      </div>
      <div v-if="editAutomation.then.length">
        <div
          v-for="then in editAutomation.then"
          :key="then"
        >
          <ion-item
            lines="none"
            class="rounded-corners mt-10 h-65"
            @click="editAction(then)"
          >
            <div slot="start">
              <i class="material-icons">{{ then.icon }}</i>
            </div>
            <div slot="start">
              <ion-label>
                <ion-text class="text-16 text--white">
                  {{ then.actionName }}
                </ion-text><br>
                <ion-text
                  class="text-12 text--gray"
                >
                  {{ then.description }}
                </ion-text>
              </ion-label>
            </div>
            <div slot="end">
              <i class="material-icons">chevron_right</i>
            </div>
          </ion-item>
        </div>
      </div>
      <div
        class="mt-30"
      >
        <div @click="automationActionModal">
          <ion-icon
            class="add-icon rounded-corners-20"
            :icon="addOutline"
          />
        </div>
        <ion-label style="color: rgba(217, 225, 217, 0.4);">
          {{ $t('Add action') }}
        </ion-label>
      </div>
    </div>
    <!-- <ion-fab
      slot="fixed"
      vertical="bottom"
      horizontal="end"
    >
      <ion-fab-button @click="presentActionSheet">
        <ion-icon
          color="dark"
          :icon="closeOutline"
        />
      </ion-fab-button>
    </ion-fab> -->
  </ion-content>
</template>

<script>
import { closeOutline, addOutline, chevronBackOutline, checkmarkDoneOutline } from 'ionicons/icons'
import { actionSheetController, modalController } from '@ionic/vue'
import { Keyboard } from '@capacitor/keyboard'

import { mapState } from 'vuex'

import automationActionModal from './AutomationAction.vue'
import editActionModal from './EditAction.vue'
import automationConditionModal from './AutomationCondition.vue'
import editConditionModal from './EditCondition.vue'

export default {
  props: {
    close: { type: Function, default: null },
    automation: { type: Object, default: () => {} },
    editedAutomationToSave: { type: Function, default: null }
  },
  data () {
    return {
      closeOutline,
      editAutomation: {},
      originalAutomationName: '',
      addOutline,
      chevronBackOutline,
      checkmarkDoneOutline,
      selectedView: '1'
    }
  },
  computed: {
    ...mapState(['propertyDevices', 'propertyScenes']),
    platform () {
      return this.$store.state.platform
    }
  },
  created () {
    this.originalAutomationName = this.automation.automationName
    this.editAutomation = this.automation
  },
  methods: {
    dismissModal () {
      this.close()
    },
    closeModal () {
      modalController.dismiss()
    },
    async editCondition (ifCondition) {
      const actionSheet = await actionSheetController
        .create({
          cssClass: 'action-sheet',
          buttons: [
            {
              text: this.$t('Edit Condition'),
              role: '',
              handler: () => {
                console.log('edit')
                this.editConditionModal(ifCondition)
              }
            },
            {
              text: this.$t('Remove Condition'),
              role: 'destructive',
              handler: () => {
                const index = this.editAutomation.if.findIndex(item => item.conditionName === ifCondition.conditionName)
                this.editAutomation.if.splice(index, 1)
              }
            }
          ]
        })
      await actionSheet.present()
    },
    async editAction (thenAction) {
      const actionSheet = await actionSheetController
        .create({
          cssClass: 'action-sheet',
          buttons: [
            {
              text: this.$t('Edit Action'),
              role: '',
              handler: () => {
                this.editActionModal(thenAction)
              }
            },
            {
              text: this.$t('Remove Action'),
              role: 'destructive',
              handler: () => {
                const index = this.editAutomation.then.findIndex(item => item.actionName === thenAction.actionName)
                this.editAutomation.then.splice(index, 1)
              }
            }
          ]
        })
      await actionSheet.present()
    },
    automationActionModal () {
      return modalController
        .create({
          component: automationActionModal,
          cssClass: 'devices-modal',
          swipeToClose: true,
          showBackdrop: false,
          backdropDismiss: true,
          componentProps: {
            devices: this.propertyDevices,
            scenes: this.propertyScenes,
            existingThenConditions: this.editAutomation.then,
            close: () => this.closeModal(),
            thenActionToSet: (action) => this.thenActionToSet(action)
          }
        })
        .then(m => m.present())
    },
    thenActionToSet (action) {
      this.editAutomation.icon = action.icon
      this.editAutomation.room = action.room
      this.editAutomation.actionDescription = action.description
      this.editAutomation.then.push(action)
    },
    editActionModal (thenAction) {
      return modalController
        .create({
          component: editActionModal,
          cssClass: 'devices-modal',
          swipeToClose: true,
          showBackdrop: false,
          backdropDismiss: true,
          componentProps: {
            devices: this.propertyDevices,
            scenes: this.propertyScenes,
            thenActionToEdit: thenAction,
            existingThenConditions: this.editAutomation.then,
            close: () => this.closeModal(),
            editedActionToSave: (action) => this.editedActionToSave(action)
          }
        })
        .then(m => m.present())
    },
    editedActionToSave (action) {
      this.editAutomation.icon = action.icon
      this.editAutomation.room = action.room
      this.editAutomation.actionDescription = action.description
      const index = this.editAutomation.then.findIndex(item => item.actionName === action.actionName)
      this.editAutomation.then.splice(index, 1)
      this.editAutomation.then.push(action)
    },
    automationConditionModal () {
      return modalController
        .create({
          component: automationConditionModal,
          cssClass: 'devices-modal',
          swipeToClose: true,
          showBackdrop: false,
          backdropDismiss: true,
          componentProps: {
            devices: this.propertyDevices,
            scenes: this.propertyScenes,
            existingIfConditions: this.editAutomation.if,
            close: () => this.closeModal(),
            ifConditionToSet: (con) => this.ifConditionToSet(con)
          }
        })
        .then(m => m.present())
    },
    ifConditionToSet (condition) {
      this.editAutomation.conditionDescription = condition.description
      this.editAutomation.if.push(condition)
    },
    editConditionModal (ifCondition) {
      return modalController
        .create({
          component: editConditionModal,
          cssClass: 'devices-modal',
          swipeToClose: true,
          showBackdrop: false,
          backdropDismiss: true,
          componentProps: {
            devices: this.propertyDevices,
            scenes: this.propertyScenes,
            ifCondition: ifCondition,
            close: () => this.closeModal(),
            editedConditionToSave: (con) => this.editedConditionToSave(con)
          }
        })
        .then(m => m.present())
    },
    editedConditionToSave (condition) {
      this.editAutomation.conditionDescription = condition.description
      const index = this.editAutomation.if.findIndex(item => item.conditionName === condition.conditionName)
      this.editAutomation.if.splice(index, 1)
      this.editAutomation.if.push(condition)
    },
    saveAutomation () {
      this.$store.dispatch('editAutomation', { originalAutomationName: this.originalAutomationName, editedAutomation: this.editAutomation }).then(response => {
        this.dismissModal()
      })
    },
    closeKeyboard () {
      Keyboard.hide()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
@import "~@/theme/_backgrounds";

.background {
    @include background-component-automation;
}

.add-icon {
    font-size: 24px;
    color: colors(primary, base);
    padding: 7px 17px 7px 17px;
    background: rgba(120, 120, 128, 0.32);;
}
ion-fab {
  margin-right: 5px;
}
ion-item {
   @include background-item--dark-transparent;
}
.h-65 {
   --min-height: 65px;
   @include bg-box-item;
}
.material-icons {
  color: colors(medium, base);
}

</style>
