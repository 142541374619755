<template>
  <div class="background--modal" />
  <ion-header class="ion-no-border text-center">
    <ion-toolbar color="transparent">
      <ion-button
        v-if="selectedView === '1'"
        slot="start"
        fill="clear"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="dismissModal()"
      >
        <img
          src="../../assets/images/arrow-go-back-line.svg"
        >
      </ion-button>
      <ion-button
        v-else
        slot="start"
        fill="clear"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="selectedView = '1'"
      >
        <img
          src="../../assets/images/arrow-go-back-line.svg"
        >
      </ion-button>
      <ion-title
        class="text--white text-18"
      >
        {{ $t('Then action') }}
      </ion-title>
      <ion-button
        slot="end"
        fill="clear"
        :disabled="!thenAction.setting.length"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="saveAction(selectedView)"
      >
        <ion-icon
          color="primary"
          size="large"
          :icon="checkmarkDoneOutline"
        />
      </ion-button>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <div v-if="selectedView === '1'">
      <div
        v-for="action in actions"
        :key="action"
      >
        <ion-item
          lines="none"
          button
          :disabled="!action.active || doesActionExists(action.name)"
          detail="false"
          class="rounded-corners mt-8 h-65"
          @click="selectedView = action.view"
        >
          <div slot="start">
            <i class="material-icons text--gray">{{ action.icon }}</i>
          </div>
          <ion-label>
            <ion-text class="text-16 text--white">
              {{ action.displayName }}
            </ion-text><br>
            <ion-text class="text-12 text--gray">
              {{ action.description }}
            </ion-text>
          </ion-label>
          <div>
            <i class="material-icons text--gray">keyboard_arrow_right</i>
          </div>
        </ion-item>
      </div>
    </div>
    <div v-else-if="selectedView === 'controlDevice'">
      <div
        v-for="group, roomId in groupedDevices"
        :key="roomId"
        class="text-left"
      >
        <div class="mt-8" />
        <ion-text class="text--white text-14">
          {{ deviceRoom(roomId) }}
        </ion-text>
        <ion-list
          v-for="device in group"
          :key="device"
        >
          <ion-item
            :disabled="thenAction.setting.length && thenAction.setting[0].ieee !== device.ieee || deviceDoesAlreadyExists(device.ieee)"
            lines="none"
            color="transparent"
            class="device-item mt-8 rounded-corners text--white"
          >
            <i
              slot="start"
              class="material-icons"
            >
              {{ deviceActionIcon(device.zigbeeModel) }}
            </i>
            <ion-label>
              {{ device.deviceName }}<br>
              <ion-text
                class="text-12 text--gray"
              >
                {{ deviceActionDescrition(device) }} <span v-if="deviceExistsInAction(device)">{{ deviceTimerDescription(device) }}</span>
              </ion-text>
            </ion-label>
            <i
              v-if="deviceExistsInAction(device)"
              class="mdi mdi-alarm text--primary pr-10"
              style="font-size: 24px"
              @click="setTimer(device)"
            />
            <ion-toggle
              v-if="deviceExistsInAction(device)"
              :value="deviceActive(device) ? false : true"
              :checked="deviceActive(device) === true"
              @ionChange="activateDevice($event.target.value, device)"
            />
            <i
              slot="end"
              class="material-icons"
              :class="deviceExistsInAction(device) ? 'device-exists' : 'device-doesnt-exist'"
              @click="addOrRemoveDeviceFromAction(device)"
            >add_circle_outline</i>
          </ion-item>
        </ion-list>
      </div>
    </div>
    <div v-else-if="selectedView === 'setScene'">
      <div
        v-for="oneScene in scenes"
        :key="oneScene"
      >
        <ion-item
          lines="none"
          :disabled="sceneSelected(oneScene.sceneName)"
          color="transparent"
          class="rounded-corners mt-10 device-item"
        >
          <ion-text
            slot="start"
            class="text--white text-16"
          >
            {{ $t('Activate scene ') }}<span class="text--primary">{{ oneScene.sceneName }}</span>
          </ion-text>
          <ion-toggle
            slot="end"
            :value="oneScene.sceneName"
            @ionChange="selectScene($event.target.value)"
          />
        </ion-item>
      </div>
    </div>
    <div v-else-if="selectedView === 'notifySomeone'">
      <ion-item
        class="device-item rounded-corners text--white"
        color="transparent"
        lines="none"
      >
        <ion-label>{{ $t('Notify me on my email') }}</ion-label>
        <ion-toggle
          slot="end"
          :value="notifyMe"
          @ionChange="notifyMeOnEmail($event.target.value)"
        />
      </ion-item>
      <ion-item
        class="device-item rounded-corners text--white mt-8"
        color="transparent"
        lines="none"
      >
        <ion-label>{{ $t('Notify someone else') }}</ion-label>
        <ion-toggle
          slot="end"
          :value="notifySomeoneElse"
          @ionChange="notifySomeoneElseByEmail($event.target.value)"
        />
      </ion-item>
      <ion-item
        v-if="notifySomeoneElse"
        lines="none"
        class="h-45 text--white mt-8 rounded-corners"
      >
        <ion-input
          v-model="notifySomeoneElseEmails"
          :multiple="true"
          type="email"
          enterkeyhint="done"
          inputmode="email"
          :placeholder="$t('Enter email')"
        />
      </ion-item>
    </div>
  </ion-content>
</template>

<script>
import { popoverController } from '@ionic/vue'

import { closeOutline, addOutline, chevronBackOutline, checkmarkDoneOutline } from 'ionicons/icons'

import DeviceTimerPopOver from '../../components/Popovers/DeviceTimerPopOver'

export default {
  props: {
    devices: { type: Array, default: () => [] },
    scenes: { type: Array, default: () => [] },
    close: { type: Function, default: null },
    existingThenConditions: { type: Array, default: () => [] },
    ifCondition: { type: Array, default: () => [] },
    thenActionToSet: { type: Function, default: null }
  },
  data () {
    return {
      closeOutline,
      addOutline,
      chevronBackOutline,
      checkmarkDoneOutline,
      selectedView: '1',
      notifySomeoneElse: false,
      notifySomeoneElseEmails: '',
      notifyMe: false,
      actions: [
        {
          active: true,
          icon: 'important_devices',
          name: 'Control Device',
          displayName: this.$t('ControlDeviceAutomation'),
          description: this.$t('Ex. Turn bulb on'),
          view: 'controlDevice'
        },
        {
          active: true,
          icon: 'subscriptions',
          name: 'Set Scene',
          displayName: this.$t('Set Scene'),
          description: this.$t('Ex. Set scene away to active'),
          view: 'setScene'
        },
        {
          active: true,
          icon: 'alternate_email',
          name: 'Notify Someone',
          displayName: this.$t('Notify Someone'),
          description: this.$t('Ex. Email me when motion detected'),
          view: 'notifySomeone'
        }
      ],
      thenAction: {
        actionName: '',
        description: '',
        icon: '',
        room: [],
        setting: []
      }
    }
  },
  computed: {
    groupedDevices () {
      return groupBy(this.devices, 'propertyRoomId')
    }
    // temperatureIfExists () {
    //   let result
    //   this.ifCondition.forEach(item => {
    //     if (item.conditionNameBackEnd === 'Indoor Temperature') {
    //       result = true
    //     } else {
    //       result = false
    //     }
    //   })
    //   return result
    // }
  },
  methods: {
    deviceRoom (id) {
      const roomId = Number(id)
      const rooms = this.$store.state.propertyRooms
      const found = rooms.find(element => element.id === roomId)
      return found ? found.roomName : '--'
    },
    deviceActionName (zigbeeModel) {
      if (zigbeeModel === 'SmartLightColorDimmable') {
        return this.$t('dimmableBulb')
      } else if (zigbeeModel === 'SmartLightColorChangeable') {
        return 'Color Bulb'
      } else if (zigbeeModel === 'SmartPlug') {
        return this.$t('smartPlug')
      } else if (zigbeeModel === 'WallSwitch') {
        return this.$t('wallSwitch')
      }
    },
    deviceActionNameBackEnd (zigbeeModel) {
      if (zigbeeModel === 'SmartLightColorDimmable' || zigbeeModel === 'SmartLightColorChangeable') {
        return 'Smart Light'
      } else if (zigbeeModel === 'SmartPlug') {
        return 'Smart Plug'
      } else if (zigbeeModel === 'WallSwitch') {
        return 'Smart Switch'
      }
    },
    deviceActionIcon (zigbeeModel) {
      if (zigbeeModel === 'SmartLightColorDimmable' || zigbeeModel === 'SmartLightColorChangeable') {
        return 'light'
      } else if (zigbeeModel === 'SmartPlug') {
        return 'electrical_services'
      } else if (zigbeeModel === 'WallSwitch') {
        return 'toggle_on'
      }
    },
    deviceActionDescrition (device) {
      let deviceStatus = this.$t('ON or OFF')
      if (this.deviceExistsInAction(device)) {
        deviceStatus = this.deviceActive(device) ? this.$t('ON') : this.$t('OFF')
      }
      if (device.zigbeeModel === 'SmartLightColorDimmable' || device.zigbeeModel === 'SmartLightColorChangeable') {
        return this.$t('Turn light ') + deviceStatus
      } else if (device.zigbeeModel === 'SmartPlug') {
        return this.$t('Turn plug ') + deviceStatus
      } else if (device.zigbeeModel === 'WallSwitch') {
        return this.$t('Turn smart switch ') + deviceStatus
      }
    },
    async setTimer (device) {
      const popover = await popoverController
        .create({
          component: DeviceTimerPopOver,
          cssClass: 'popover-timer',
          translucent: false,
          componentProps: {
            device,
            timerToSet: (timer) => this.setTimerForDevice(timer),
            close: () => popover.dismiss()
          }
        })
      await popover.present()
    },
    setTimerForDevice (timer) {
      this.thenAction.setting[0].timer = timer.setTimerTo
      console.log(this.thenAction.setting)
    },
    deviceTimerActive (device) {
      return this.thenAction.setting[0].timer ? this.thenAction.setting[0].timer : false
    },
    deviceTimerDescription (device) {
      let deviceStatus = ''
      if (this.deviceExistsInAction(device) && this.deviceTimerActive(device)) {
        deviceStatus = this.deviceTimerActive(device)
      } else {
        deviceStatus = this.$t('Forever')
      }
      return deviceStatus
    },
    deviceActive (device) {
      const deviceIndex = this.thenAction.setting.findIndex(item => item.ieee === device.ieee)

      const selectedDevice = this.thenAction.setting[deviceIndex]
      if (selectedDevice.switchgear === 'on') {
        return true
      } else {
        return false
      }
    },
    activateDevice (value, device) {
      const deviceIndex = this.thenAction.setting.findIndex(item => item.ieee === device.ieee)
      let onOff
      if (value === 'true') {
        onOff = 'on'
      } else {
        onOff = 'off'
      }
      this.thenAction.setting[deviceIndex].switchgear = onOff
    },
    deviceExistsInAction (device) {
      const deviceIndex = this.thenAction.setting.findIndex(item => item.ieee === device.ieee)
      if (deviceIndex === -1) {
        return false
      } else {
        return true
      }
    },
    addOrRemoveDeviceFromAction (device) {
      const deviceIndex = this.thenAction.setting.findIndex(item => item.ieee === device.ieee)
      if (deviceIndex === -1) {
        this.thenAction.setting.push(device)
      } else {
        this.thenAction.setting.splice(deviceIndex, 1)
      }
    },
    deviceDoesAlreadyExists (ieee) {
      const items = this.existingThenConditions
      console.log(items)
      let result = false
      items.forEach(item => {
        if (item.setting[0].ieee === ieee) {
          result = true
        }
      })
      console.log(result)
      return result
    },
    doesActionExists (actionName) {
      const items = this.existingThenConditions
      console.log(actionName)
      console.log(items)
      let result = false
      items.forEach(item => {
        if (item.actionName === actionName) {
          result = true
        }
      })
      return result
    },
    // Scene Actions ================================================================================================================
    sceneSelected (sceneName) {
      if (!this.thenAction.setting.length) {
        return false
      } else {
        if (this.thenAction.setting[0].setScene === sceneName) {
          return false
        } else {
          return true
        }
      }
    },
    selectScene (sceneName) {
      const sceneToSet = {
        setScene: sceneName
      }
      if (this.thenAction.setting.length) {
        this.thenAction.setting = []
      } else {
        this.thenAction.setting.push(sceneToSet)
      }
      console.log(this.thenAction)
    },
    // Notify me on my email ==============================================
    notifyMeOnEmail (value) {
      if (value === 'false') {
        this.notifyMe = true
        const notifyMeOnEmail = this.$store.state.userData[0].email
        this.thenAction.setting.push(notifyMeOnEmail)
      }
      if (value === 'true') {
        this.notifyMe = false
        this.thenAction.setting = []
      }
      console.log(this.thenAction)
    },
    notifySomeoneElseByEmail (value) {
      if (value === 'false') {
        this.notifySomeoneElse = true
        const someoneElse = 'someone@else.com'
        this.thenAction.setting.push(someoneElse)
      }
      if (value === 'true') {
        this.notifySomeoneElse = false
        const index = this.thenAction.setting.findIndex(item => item === 'someone@else.com')
        this.thenAction.setting.splice(index, 1)
      }
    },
    async saveAction (selectedView) {
      if (selectedView === 'controlDevice') {
        const desc = await this.deviceActionDescrition(this.thenAction.setting[0])
        console.log(desc)
        this.thenAction.setting[0].timer = this.thenAction.setting[0].timer ? this.thenAction.setting[0].timer : 'forever'
        this.thenAction.room = this.thenAction.setting[0].propertyRoomId
        this.thenAction.icon = this.deviceActionIcon(this.thenAction.setting[0].zigbeeModel)
        this.thenAction.actionName = this.deviceActionName(this.thenAction.setting[0].zigbeeModel)
        this.thenAction.actionNameBackEnd = this.deviceActionNameBackEnd(this.thenAction.setting[0].zigbeeModel)
        this.thenAction.description = desc + ' ' + this.thenAction.setting[0].timer
      }
      if (selectedView === 'setScene') {
        this.thenAction.actionName = this.$t('Set Scene')
        this.thenAction.actionNameBackEnd = 'Set Scene'
        this.thenAction.description = `${this.$t('Set scene')} ${this.thenAction.setting[0].setScene}`
        this.thenAction.icon = 'subscriptions'
      }
      if (selectedView === 'notifySomeone') {
        this.thenAction.actionName = this.$t('Notify Someone')
        this.thenAction.actionNameBackEnd = 'Notify Someone'
        this.thenAction.icon = 'alternate_email'
        if (this.notifySomeoneElse) {
          const index = this.thenAction.setting.findIndex(item => item === 'someone@else.com')
          this.thenAction.setting.splice(index, 1)
          this.thenAction.setting.push(this.notifySomeoneElseEmails)
        }
        let description = ''
        this.thenAction.setting.forEach(item => {
          description = description + item + ','
        })
        this.thenAction.description = `${this.$t('Notify')} ${description}`
      }
      this.thenActionToSet(this.thenAction)
      console.log(this.thenAction)
      this.dismissModal()
    },
    dismissModal () {
      this.close()
    }
  }
}

function groupBy (array, key) {
  const result = {}
  array.forEach(item => {
    if (item.tunable) {
      if (!result[item[key]]) {
        result[item[key]] = []
      }
      result[item[key]].push(item)
    }
  })

  return result
}
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
@import "~@/theme/_backgrounds";

.background {
    @include background-component-automation;
}

.material-icons {
    color: colors(medium, base);
}

ion-list {
    background: none;
}

.h-65 {
   --min-height: 65px;
   @include bg-box-item;
}

.h-45 {
   --min-height: 45px;
   @include bg-box-item;
}

.material-icons {
  font-size: 24px;
}

.device-exists {
  color: colors(primary, base);
}

.device-doesnt-exists {
  color: colors(light, base);
}
</style>
