<template>
  <ion-page>
    <ion-header>
      <main-view-header />
    </ion-header>
    <ion-content class="ion-padding-start ion-padding-end">
      <div
        v-if="Object.keys(propertyAutomation).length === 0 && !loading"
        class="text--gray"
        style="margin-top: 25vh;"
      >
        <i
          class="material-icons icons"
          style="font-size: 50px;"
        >model_training</i><br><br>
        {{ $t('No automation found here..') }}<br><br>
        {{ $t('Start by adding them') }}
      </div>
      <div
        v-if="loading"
        class="text--gray"
      >
        <ion-spinner
          color="light"
          name="crescent"
        /><br><br>
        {{ $t('Loading') }}....
      </div>
      <ion-list v-else>
        <div
          v-for="automation in propertyAutomation"
          :key="automation"
        >
          <ion-item
            detail="false"
            lines="none"
            color="transparent"
            class="device-item rounded-corners mt-8"
            @click="presentActionSheet(automation)"
          >
            <!-- <i
              slot="start"
              class="material-icons"
            >model_training</i> -->
            <ion-label slot="start">
              <div class="mt-5">
                <ion-text class="text-14 text--white">
                  {{ automation.automationName }}
                </ion-text>
              </div>
              <div class="mt-3">
                <span
                  v-for="item in automation.if"
                  :key="item"
                >
                  <i
                    class="material-icons text-14"
                    style="padding-right: 5px;"
                  >{{ item.icon }}</i>
                </span>
                <i class="mdi mdi-swap-horizontal text--primary text-16" />
                <span
                  v-for="item in automation.then"
                  :key="item"
                >
                  <i
                    class="material-icons text-14"
                    style="padding-left: 5px;"
                  >{{ item.icon }}</i>
                </span>
              </div>
            </ion-label>
            <ion-toggle
              slot="end"
              :checked="automation.active === true"
              :value="automation.active === true ? false : true"
              @click="$event.stopPropagation()"
              @ionChange="updateAutomationActiveInactive($event.target.value, automation)"
            />
          </ion-item>
        </div>
      </ion-list>
      <ion-fab
        slot="fixed"
        vertical="bottom"
        horizontal="end"
      >
        <ion-fab-button @click="addAutomationModal">
          <ion-icon
            color="dark"
            :icon="addOutline"
          />
        </ion-fab-button>
      </ion-fab>
    </ion-content>
  </ion-page>
</template>

<script>
import { addOutline } from 'ionicons/icons'
import { modalController, actionSheetController } from '@ionic/vue'
import mainViewHeader from '../components/Headers/MainViewHeaderComponent.vue'
import addNewAutomation from '../components/Automation/AddNewAutomation'
import editAutomationModal from '../components/Automation/EditAutomation.vue'

export default {
  components: {
    mainViewHeader
  },
  data () {
    return {
      addOutline,
      propertyAutomation: [],
      loading: false
    }
  },
  computed: {
  },
  watch: {
    $route: function () {
      if (this.$route.name === 'Automation' && this.userRole !== 'Landlord') {
        this.getPropertyAutomation()
      }
    }
  },
  created () {
    this.getPropertyAutomation()
  },
  methods: {
    getPropertyAutomation () {
      this.loading = true
      this.$store.dispatch('getPropertyAutomation').then(response => {
        console.log(response)
        this.propertyAutomation = response.data[0].automation
        this.loading = false
      })
    },
    updateAutomationActiveInactive (value, automation) {
      this.$store.dispatch('updateAutomationActiveInactive', { active: value, automation }).then(() => {
        this.getPropertyAutomation()
      })
    },
    addAutomationModal () {
      return modalController
        .create({
          component: addNewAutomation,
          cssClass: 'devices-modal',
          swipeToClose: true,
          showBackdrop: false,
          backdropDismiss: true,
          componentProps: {
            close: () => this.closeModal(),
            newAutomationToSet: (automation) => this.newAutomationToCreate(automation)
          }
        })
        .then(m => m.present())
    },
    editAutomationModal (automation) {
      return modalController
        .create({
          component: editAutomationModal,
          cssClass: 'devices-modal',
          swipeToClose: true,
          showBackdrop: false,
          backdropDismiss: true,
          componentProps: {
            close: () => this.closeModal(),
            automation: automation,
            editedAutomationToSave: (automation) => this.editedAutomationToSave(automation)
          }
        })
        .then(m => m.present())
    },
    newAutomationToCreate (automation) {
      this.$store.dispatch('addPropertyAutomation', automation).then(() => {
        this.getPropertyAutomation()
      })
    },
    editedAutomationToSave (automation) {
      console.log(automation)
    },
    automationRoom (id) {
      const roomId = Number(id)
      const rooms = this.$store.state.propertyRooms
      const found = rooms.find(element => element.id === roomId)
      return found ? found.roomName : '--'
    },
    closeModal () {
      modalController.dismiss()
      this.getPropertyAutomation()
    },
    async presentActionSheet (automation) {
      const actionSheet = await actionSheetController
        .create({
          cssClass: 'action-sheet',
          buttons: [
            {
              text: this.$t('Edit Automation'),
              handler: () => {
                this.editAutomationModal(automation)
              }
            },
            {
              text: this.$t('Delete Automation'),
              role: 'destructive',
              handler: () => {
                this.$store.dispatch('removePropertyAutomation', automation).then(response => {
                  this.getPropertyAutomation()
                })
              }
            },
            {
              text: this.$t('Cancel'),
              role: 'cancel',
              handler: () => {
                console.log('Cancel clicked')
              }
            }
          ]
        })
      await actionSheet.present()

      const { role } = await actionSheet.onDidDismiss()
      console.log('onDidDismiss resolved with role', role)
    }
  }
}

// function groupBy (array, key) {
//   const result = {}
//   array.forEach(item => {
//     if (!result[item[key]]) {
//       result[item[key]] = []
//     }
//     result[item[key]].push(item)
//   })
//   console.log(result)
//   return result
// }
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
@import "~@/theme/_backgrounds";

// ion-item {
//   --min-height: 65px;
// }
.material-icons {
  color: colors(medium, base);
}

ion-text {
   padding: 0;
   margin: 0;
}
.description {
  margin-bottom: -5px;
  padding: 0;
}
ion-item {
border-radius: 10px;
--ripple-color: transparent;
--background-activated: transparent;
--background-activated-opacity:transparent;
--background-focused: transparent;
--background-focused-opacity:transparent;
--background-hover  : transparent;
--background-hover-opacity  :transparent;
}

ion-skeleton-text {
    --background-rgb: 220,220,220;
}
.skeleton {
  height: 65px;
}
</style>
