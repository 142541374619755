<template>
  <div class="background--modal" />
  <ion-header class="ion-no-border text-center">
    <ion-toolbar color="transparent">
      <ion-button
        slot="start"
        fill="clear"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="dismissModal()"
      >
        <img
          src="../../assets/images/arrow-go-back-line.svg"
        >
      </ion-button>
      <ion-title class="text--white text-18">
        {{ $t('Create Automation') }}
      </ion-title>
      <ion-button
        slot="end"
        fill="clear"
        color="transparent"
        :disabled="!newAutomation.if.length || !newAutomation.then.length || !newAutomation.automationName"
        size="large"
        class="close-modal-button"
        @click="createAutomation()"
      >
        <ion-label color="primary">
          {{ $t('save') }}
        </ion-label>
      </ion-button>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding-start ion-padding-end ion-padding-bottom">
    <div v-if="selectedView === '1'">
      <div
        class="form--dark flex-1 ion-padding-bottom"
        :class="platform.includes('ios') ? 'form--dark-ios' : 'form--dark-md'"
      >
        <ion-item lines="none">
          <ion-label position="floating">
            {{ $t('Automation Name') }}
          </ion-label>
          <ion-input
            id="automationName"
            v-model="newAutomation.automationName"
            type="text"
            required
            maxlength="22"
            enterkeyhint="done"
            @keydown.enter="closeKeyboard"
          />
        </ion-item>
      </div>
      <div class="text-left text--uppercase">
        <span class="text--bold text--primary">{{ $t('IF ANY') }}</span><span style="color: rgba(217, 225, 217, 0.4);">{{ $t(' of the conditions are satisfied') }}</span>
      </div>
      <div v-if="newAutomation.if.length">
        <div
          v-for="ifCondition in newAutomation.if"
          :key="ifCondition"
        >
          <ion-item
            lines="none"
            class="rounded-corners mt-8 h-65"
            @click="editCondition(ifCondition)"
          >
            <div slot="start">
              <i class="material-icons">{{ ifCondition.icon }}</i>
            </div>
            <div slot="start">
              <ion-label>
                <ion-text class="text-16 text--white">
                  {{ ifCondition.description }}
                </ion-text><br>
                <ion-text
                  class="text-12 text--gray"
                >
                  {{ ifCondition.description2 }}
                </ion-text>
              </ion-label>
            </div>
            <div slot="end">
              <i class="material-icons">chevron_right</i>
            </div>
          </ion-item>
        </div>
      </div>
      <div
        class="mt-30"
      >
        <div @click="automationConditionModal">
          <ion-icon
            class="add-icon rounded-corners-20"
            :icon="addOutline"
          />
        </div>
        <ion-label style="color: rgba(217, 225, 217, 0.4);">
          {{ $t('Add condition') }}
        </ion-label>
      </div>
      <div class="text-left mt-30 text--uppercase">
        <span class="text--bold text--primary">{{ $t('THEN') }}</span> <span style="color: rgba(217, 225, 217, 0.4);">{{ $t('execute the following actions') }}</span>
      </div>
      <div v-if="newAutomation.then.length">
        <div
          v-for="then in newAutomation.then"
          :key="then"
        >
          <ion-item
            lines="none"
            class="rounded-corners mt-10 h-65"
            @click="editAction(then)"
          >
            <div slot="start">
              <i class="material-icons">{{ then.icon }}</i>
            </div>
            <div slot="start">
              <ion-label>
                <ion-text class="text-16 text--white">
                  {{ then.actionName }}
                </ion-text><br>
                <ion-text
                  class="text-12 text--gray"
                >
                  {{ then.description }}
                </ion-text>
              </ion-label>
            </div>
            <div slot="end">
              <i class="material-icons">chevron_right</i>
            </div>
          </ion-item>
        </div>
      </div>
      <div
        class="mt-30"
      >
        <div @click="automationActionModal">
          <ion-icon
            class="add-icon rounded-corners-20"
            :icon="addOutline"
          />
        </div>
        <ion-label style="color: rgba(217, 225, 217, 0.4);">
          {{ $t('Add action') }}
        </ion-label>
      </div>
    </div>
    <ion-fab
      slot="fixed"
      vertical="bottom"
      horizontal="end"
    >
      <ion-fab-button @click="presentActionSheet">
        <ion-icon
          color="dark"
          :icon="closeOutline"
        />
      </ion-fab-button>
    </ion-fab>
  </ion-content>
</template>

<script>
import { closeOutline, addOutline, chevronBackOutline, checkmarkDoneOutline } from 'ionicons/icons'
import { actionSheetController, modalController } from '@ionic/vue'

import automationActionModal from './AutomationAction'
import automationConditionModal from './AutomationCondition'
import editConditionModal from './EditCondition.vue'
import editActionModal from './EditAction.vue'
import { Keyboard } from '@capacitor/keyboard'

import { mapState } from 'vuex'

export default {
  props: {
    close: { type: Function, default: null },
    newAutomationToSet: { type: Function, default: null }
  },
  data () {
    return {
      closeOutline,
      addOutline,
      chevronBackOutline,
      checkmarkDoneOutline,
      selectedView: '1',
      newAutomation: {
        automationName: '',
        conditionDescription: '',
        actionDescription: '',
        active: true,
        interrupted: false,
        icon: '',
        room: '',
        if: [],
        then: []
        // { conditionName: 'Device Status', description: 'Motion sensor in Living Room', icon: 'devices' }
        // { actionName: 'Device Status', description: 'Light on in Living Room', icon: 'devices' }
      }
    }
  },
  computed: {
    ...mapState(['propertyDevices', 'propertyScenes']),
    automationName () {
      return this.newAutomation.automationName
    },
    platform () {
      return this.$store.state.platform
    }
  },
  watch: {
    automationName: function () {
      this.newAutomation.automationName = this.newAutomation.automationName.replace(/(^\w)/g, m => m.toUpperCase())
    }
  },
  methods: {
    addPropertyAutomation () {
      this.newAutomation.automationName = this.newAutomation.automationName.replace(/(^\w|\s\w)/g, m => m.toUpperCase())
      console.log('Automation name', this.newAutomation.automationName)
      this.$store.dispatch('addPropertyAutomation', this.newAutomation)
    },
    dismissModal () {
      this.close()
    },
    automationActionModal () {
      return modalController
        .create({
          component: automationActionModal,
          cssClass: 'devices-modal',
          swipeToClose: true,
          showBackdrop: false,
          backdropDismiss: true,
          componentProps: {
            devices: this.propertyDevices,
            scenes: this.propertyScenes,
            existingThenConditions: this.newAutomation.then,
            ifCondition: this.newAutomation.if,
            close: () => this.closeModal(),
            thenActionToSet: (action) => this.thenActionToSet(action)
          }
        })
        .then(m => m.present())
    },
    automationConditionModal () {
      return modalController
        .create({
          component: automationConditionModal,
          cssClass: 'devices-modal',
          swipeToClose: true,
          showBackdrop: false,
          backdropDismiss: true,
          componentProps: {
            devices: this.propertyDevices,
            scenes: this.propertyScenes,
            existingIfConditions: this.newAutomation.if,
            close: () => this.closeModal(),
            ifConditionToSet: (con) => this.ifConditionToSet(con)
          }
        })
        .then(m => m.present())
    },
    editConditionModal (ifCondition) {
      return modalController
        .create({
          component: editConditionModal,
          cssClass: 'devices-modal',
          swipeToClose: true,
          showBackdrop: false,
          backdropDismiss: true,
          componentProps: {
            devices: this.propertyDevices,
            scenes: this.propertyScenes,
            ifCondition: ifCondition,
            close: () => this.closeModal(),
            editedConditionToSave: (con) => this.editedConditionToSave(con)
          }
        })
        .then(m => m.present())
    },
    editActionModal (thenAction) {
      return modalController
        .create({
          component: editActionModal,
          cssClass: 'devices-modal',
          swipeToClose: true,
          showBackdrop: false,
          backdropDismiss: true,
          componentProps: {
            devices: this.propertyDevices,
            scenes: this.propertyScenes,
            thenActionToEdit: thenAction,
            existingThenConditions: this.newAutomation.then,
            close: () => this.closeModal(),
            editedActionToSave: (action) => this.editedActionToSave(action)
          }
        })
        .then(m => m.present())
    },
    ifConditionToSet (condition) {
      this.newAutomation.conditionDescription = condition.description
      this.newAutomation.if.push(condition)
    },
    editedConditionToSave (condition) {
      this.newAutomation.conditionDescription = condition.description
      const index = this.newAutomation.if.findIndex(item => item.conditionName === condition.conditionName)
      this.newAutomation.if.splice(index, 1)
      this.newAutomation.if.push(condition)
    },
    thenActionToSet (action) {
      this.newAutomation.icon = action.icon
      this.newAutomation.room = action.room
      this.newAutomation.actionDescription = action.description
      this.newAutomation.then.push(action)
    },
    editedActionToSave (action) {
      this.newAutomation.icon = action.icon
      this.newAutomation.room = action.room
      this.newAutomation.actionDescription = action.description
      const index = this.newAutomation.then.findIndex(item => item.actionName === action.actionName)
      this.newAutomation.then.splice(index, 1)
      this.newAutomation.then.push(action)
    },
    existingIfConditions () {
      const ifConditions = []
      this.newAutomation.if.forEach(item => {
        ifConditions.push(item.conditionName)
      })
      return ifConditions
    },
    createAutomation () {
      this.newAutomation.automationName = this.newAutomation.automationName.replace(/(^\w)/g, m => m.toUpperCase())
      this.newAutomationToSet(this.newAutomation)
      this.dismissModal()
    },
    closeModal () {
      modalController.dismiss()
    },
    async presentActionSheet () {
      const actionSheet = await actionSheetController
        .create({
          cssClass: 'action-sheet',
          buttons: [
            {
              text: this.$t('Remove Conditions (IF)'),
              role: 'destructive',
              handler: () => {
                this.newAutomation.if = []
              }
            },
            {
              text: this.$t('Remove Actions (THEN)'),
              role: 'destructive',
              handler: () => {
                this.newAutomation.then = []
              }
            }
          ]
        })
      await actionSheet.present()
    },
    async editCondition (ifCondition) {
      const actionSheet = await actionSheetController
        .create({
          cssClass: 'action-sheet',
          buttons: [
            {
              text: this.$t('Edit Condition'),
              role: '',
              handler: () => {
                console.log('edit')
                this.editConditionModal(ifCondition)
              }
            },
            {
              text: this.$t('Remove Condition'),
              role: 'destructive',
              handler: () => {
                const index = this.newAutomation.if.findIndex(item => item.conditionName === ifCondition.conditionName)
                this.newAutomation.if.splice(index, 1)
              }
            }
          ]
        })
      await actionSheet.present()
    },
    async editAction (thenAction) {
      const actionSheet = await actionSheetController
        .create({
          cssClass: 'action-sheet',
          buttons: [
            {
              text: this.$t('Edit Action'),
              role: '',
              handler: () => {
                this.editActionModal(thenAction)
              }
            },
            {
              text: this.$t('Remove Action'),
              role: 'destructive',
              handler: () => {
                const index = this.newAutomation.then.findIndex(item => item.actionName === thenAction.actionName)
                this.newAutomation.then.splice(index, 1)
              }
            }
          ]
        })
      await actionSheet.present()
    },
    closeKeyboard () {
      Keyboard.hide()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
@import "~@/theme/_backgrounds";

.background {
    @include background-component-automation;
}

.add-icon {
    font-size: 24px;
    color: colors(primary, base);
    padding: 7px 17px 7px 17px;
    background: rgba(120, 120, 128, 0.32);;
}
ion-fab {
  margin-right: 5px;
}
ion-item {
   @include background-item--dark-transparent;
}
.h-65 {
   --min-height: 65px;
   @include bg-box-item;
}
.material-icons {
  color: colors(medium, base);
}
// ion-segment {
//     color: white;
//   --background: transparent;
//   min-height: 44px;

// ion-segment-button {
//   --color-checked: #{colors(primary, base)};
//   --indicator-color: transparent !important;
//   --indicator-color-checked: transparent !important;
//   --indicator-box-shadow: transparent!important;
//   --border-width:0px;
// }
// .segment-button-checked {
//   font-weight: medium;
//   position: relative;
//   font-weight: medium;
// }
// .segment-button-checked::after{
//   content : "";
//   position: absolute;
//   left: 35%;
//   bottom: 0px;
//   width: 30%;
//   border-bottom : solid 3px colors(primary, base);
// }
// }

</style>
