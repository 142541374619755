<template>
  <div class="background--modal" />
  <ion-header class="ion-no-border text-center">
    <ion-toolbar color="transparent">
      <ion-button
        v-if="selectedViewCondition === '1'"
        slot="start"
        fill="clear"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="dismissModal()"
      >
        <img
          src="../../assets/images/arrow-go-back-line.svg"
        >
      </ion-button>
      <ion-button
        v-else
        slot="start"
        fill="clear"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="selectedViewCondition = '1'"
      >
        <img
          src="../../assets/images/arrow-go-back-line.svg"
        >
      </ion-button>
      <ion-title
        v-if="selectedViewCondition === '1'"
        class="text--white text-18"
      >
        {{ $t('Add condition (IF)') }}
      </ion-title>
      <ion-title
        v-else
        class="text--white text-18"
      >
        {{ viewName(selectedViewCondition) }}
      </ion-title>
      <ion-button
        v-if="selectedViewCondition !== '1'"
        slot="end"
        fill="clear"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="saveCondition(selectedViewCondition)"
      >
        <ion-icon
          color="primary"
          size="large"
          :icon="checkmarkDoneOutline"
        />
      </ion-button>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <div v-if="selectedViewCondition === '1'">
      <div
        v-for="condition in conditions"
        :key="condition"
      >
        <ion-item
          lines="none"
          :disabled="doesAlreadyExists(condition.name) || !condition.active"
          button
          detail="false"
          class="rounded-corners mt-8 h-65"
          @click="condition.geoFenceEnabled ? selectedViewCondition = condition.view : openSettingsAlert()"
        >
          <div slot="start">
            <i class="material-icons text--gray">{{ condition.icon }}</i>
          </div>
          <ion-label>
            <ion-text class="text-16 text--white">
              {{ condition.displayName }}
            </ion-text><br>
            <ion-text class="text-12 text--gray">
              <span>{{ condition.description }}</span>
            </ion-text>
          </ion-label>
          <div>
            <i class="material-icons text--gray">keyboard_arrow_right</i>
          </div>
        </ion-item>
      </div>
    </div>
    <div v-else-if="selectedViewCondition === 'scheduleTime'">
      <div class="d-flex justify-content-center">
        <ion-item
          lines="none"
          class="text--white text--bold text--14"
          color="transparent"
        >
          <ion-text class="pr-5">
            {{ $t('Start Time') }}
          </ion-text>
        </ion-item>
        <ion-item
          lines="none"
          class="text--white text--bold text--14"
          color="transparent"
        >
          <ion-text class="pr-50">
            {{ $t('End Time') }}
          </ion-text>
        </ion-item>
      </div>
      <div class="d-flex justify-content-center">
        <ion-item
          lines="none"
          :disabled="scheduleTime.setting.wholeDay || scheduleTime.setting.sunrise"
          class="text--primary ion-no-padding text--bold"
          color="transparent"
        >
          <ion-datetime
            v-model="scheduleTime.setting.startTime"
            display-format="HH:mm"
          />
        </ion-item>
        <ion-item
          lines="none"
          :disabled="scheduleTime.setting.wholeDay || scheduleTime.setting.sunrise"
          class="text--white ion-no-padding text--bold"
          color="transparent"
        >
          <ion-datetime
            v-model="scheduleTime.setting.endTime"
            display-format="HH:mm"
          />
        </ion-item>
      </div>
      <ion-item
        lines="none"
        :disabled="scheduleTime.setting.sunrise"
        color="transparent"
        class="rounded-corners mt-30 device-item"
      >
        <ion-text
          slot="start"
          class="text--white text-16"
        >
          {{ $t('Whole day') }}
        </ion-text>
        <ion-toggle
          slot="end"
          v-model="scheduleTime.setting.wholeDay"
        />
      </ion-item>
      <ion-item
        lines="none"
        :disabled="scheduleTime.setting.wholeDay"
        color="transparent"
        class="rounded-corners mt-10 device-item"
      >
        <ion-text
          slot="start"
          class="text--white text-16"
        >
          {{ $t('From sunrise till sunset') }}
        </ion-text>
        <ion-toggle
          slot="end"
          v-model="scheduleTime.setting.sunrise"
        />
      </ion-item>
      <div class="mt-30 text-left">
        <ion-text class="text--uppercase text--white text--bold">
          {{ $t('select days') }}
        </ion-text>
        <ion-row
          lines="none"
          class="ion-no-padding mt-10"
          color="transparent"
        >
          <ion-button
            class="day-button"
            :class="dayExists('Mon') ? 'day-active' : 'day-inactive'"
            @click="addRemoveDay('Mon')"
          >
            {{ $t('Mon') }}
          </ion-button>
          <ion-button
            class="day-button"
            :class="dayExists('Tue') ? 'day-active' : 'day-inactive'"
            @click="addRemoveDay('Tue')"
          >
            {{ $t('Tue') }}
          </ion-button>
          <ion-button
            class="day-button"
            :class="dayExists('Wed') ? 'day-active' : 'day-inactive'"
            @click="addRemoveDay('Wed')"
          >
            {{ $t('Wed') }}
          </ion-button>
          <ion-button
            class="day-button"
            :class="dayExists('Thu') ? 'day-active' : 'day-inactive'"
            @click="addRemoveDay('Thu')"
          >
            {{ $t('Thu') }}
          </ion-button>
          <ion-button
            class="day-button"
            :class="dayExists('Fri') ? 'day-active' : 'day-inactive'"
            @click="addRemoveDay('Fri')"
          >
            {{ $t('Fri') }}
          </ion-button>
          <ion-button
            class="day-button"
            :class="dayExists('Sat') ? 'day-active' : 'day-inactive'"
            @click="addRemoveDay('Sat')"
          >
            {{ $t('Sat') }}
          </ion-button>
          <ion-button
            class="day-button"
            :class="dayExists('Sun') ? 'day-active' : 'day-inactive'"
            @click="addRemoveDay('Sun')"
          >
            {{ $t('Sun') }}
          </ion-button>
        </ion-row>
      </div>
      <div
        v-if="errorMessage"
        class="mt-8 text--primary"
      >
        {{ errorMessage }}
      </div>
    </div>
    <div v-else-if="selectedViewCondition === 'deviceStatus'">
      <ion-text v-if="!devices.length">
        {{ $t('No devices in your property') }}
      </ion-text>
      <div
        v-for="device in devices"
        :key="device"
      >
        <ion-item
          v-if="device.zigbeeModel === 'SensorMotion' || device.zigbeeModel === 'SensorDoorWindow' || device.zigbeeModel === 'SensorVibration'"
          :disabled="deviceStatus.setting.length && deviceStatus.setting[0].ieee !== device.ieee"
          lines="none"
          color="transparent"
          class="device-item rounded-corners mt-8"
        >
          <i
            slot="start"
            class="material-icons text--primary"
          >
            {{ device.zigbeeModel === 'SensorMotion' || device.zigbeeModel === 'SensorVibration' ? 'sensors' : 'sensor_door' }}
          </i>
          <ion-label>
            <ion-text class="text--white">
              {{ deviceStatusName(device.zigbeeModel) }}
            </ion-text><br>
            <ion-text class="text-14 text--gray">
              {{ $t('in') }} {{ deviceRoom(device.propertyRoomId) }}
            </ion-text>
          </ion-label>
          <ion-toggle
            slot="end"
            :value="deviceExistsInCondition(device) ? false : true"
            @ionChange="addOrRemoveDeviceFromCondition($event.target.value, device)"
          />
        </ion-item>
      </div>
      <div
        v-if="errorMessage"
        class="mt-8 text--primary"
      >
        {{ errorMessage }}
      </div>
    </div>
    <div v-else-if="selectedViewCondition === 'weather'">
      <div
        v-for="condition in weatherConditions"
        :key="condition"
      >
        <ion-item
          lines="none"
          color="transparent"
          :disabled="weatherSelected(condition.value)"
          class="rounded-corners mt-10 device-item"
        >
          <ion-text
            slot="start"
            class="text--white text-16"
          >
            {{ $t('If outside is') }} <span class="text--primary">{{ condition.name }}</span>
          </ion-text>
          <ion-toggle
            slot="end"
            :value="condition.value"
            @ionChange="selectWeather($event.target.value)"
          />
        </ion-item>
      </div>
      <div
        v-if="errorMessage"
        class="mt-8 text--primary"
      >
        {{ errorMessage }}
      </div>
    </div>
    <div v-else-if="selectedViewCondition === 'scene'">
      <div
        v-for="oneScene in scenes"
        :key="oneScene"
      >
        <ion-item
          lines="none"
          color="transparent"
          :disabled="sceneSelected(oneScene.sceneName)"
          class="rounded-corners mt-10 device-item"
        >
          <ion-text
            slot="start"
            class="text--white text-16"
          >
            {{ $t('If active scene is') }} <span class="text--primary">{{ oneScene.sceneName }}</span>
          </ion-text>
          <ion-toggle
            slot="end"
            :value="oneScene.sceneName"
            @ionChange="selectScene($event.target.value)"
          />
        </ion-item>
      </div>
      <div
        v-if="errorMessage"
        class="mt-8 text--primary"
      >
        {{ errorMessage }}
      </div>
    </div>
    <div v-else-if="selectedViewCondition === 'security'">
      <ion-item
        lines="none"
        color="transparent"
        class="rounded-corners mt-10 device-item"
      >
        <ion-text
          slot="start"
          class="text--white text-16"
        >
          {{ $t('If alarm is') }} <span class="text--primary">{{ security.setting.security ? $t('ON') : $t('OFF') }}</span>
        </ion-text>
        <ion-toggle
          slot="end"
          v-model="security.setting.security"
        />
      </ion-item>
      <div
        v-if="errorMessage"
        class="mt-8 text--primary"
      >
        {{ errorMessage }}
      </div>
    </div>
    <div v-else-if="selectedViewCondition === 'indoorTemperature'">
      <ion-item
        lines="none"
        color="transparent"
        class="rounded-corners mt-10 device-item text--white"
      >
        <ion-label>
          <span class="text--white">{{ $t('Temperature') }}</span>
        </ion-label>
        <ion-select
          slot="end"
          v-model="indoorTemp.setting.temp"
          class="text--primary text--bold"
          placeholder="Select One"
        >
          <ion-select-option
            v-for="{ temp, value } in tempRange"
            :key="temp"
            :value="value"
          >
            {{ temp }}
          </ion-select-option>
        </ion-select>
        <i
          slot="end"
          class="mdi mdi-chevron-down text--white"
        />
      </ion-item>
      <ion-item
        lines="none"
        :disabled="indoorTemp.setting.isBelow"
        color="transparent"
        class="rounded-corners mt-10 device-item text--white"
      >
        <ion-label>
          <span class="text--white">{{ $t('Is above') }}</span>
        </ion-label>
        <ion-toggle v-model="indoorTemp.setting.isAbove" />
      </ion-item>
      <ion-item
        lines="none"
        :disabled="indoorTemp.setting.isAbove"
        color="transparent"
        class="rounded-corners mt-10 device-item text--white"
      >
        <ion-label>
          <span class="text--white">{{ $t('Is below') }}</span>
        </ion-label>
        <ion-toggle v-model="indoorTemp.setting.isBelow" />
      </ion-item>
      <div
        v-if="errorMessage"
        class="mt-8 text--primary"
      >
        {{ errorMessage }}
      </div>
    </div>
    <div v-else-if="selectedViewCondition === 'userLocation'">
      <ion-item
        lines="none"
        class="rounded-corners mt-10 h-65 text--white"
      >
        <ion-label>
          <span class="text--white">{{ $t('When') }}</span>
        </ion-label>
        <ion-select
          slot="end"
          v-model="userLocation.setting.where"
          class="text--primary text--bold"
          placeholder="Select One"
        >
          <ion-select-option
            v-for="{ name, value } in userLocationStatus"
            :key="name"
            :value="value"
          >
            {{ name }}
          </ion-select-option>
        </ion-select>
        <i
          slot="end"
          class="mdi mdi-chevron-down text--white"
        />
      </ion-item>
      <div
        v-if="errorMessage"
        class="mt-8 text--primary"
      >
        {{ errorMessage }}
      </div>
    </div>
  </ion-content>
</template>

<script>
import { modalController, alertController } from '@ionic/vue'
import { closeOutline, addOutline, chevronBackOutline, checkmarkDoneOutline } from 'ionicons/icons'
import settingsModal from '../SettingsModal.vue'

export default {
  props: {
    devices: { type: Array, default: () => [] },
    scenes: { type: Array, default: () => [] },
    existingIfConditions: { type: Array, default: () => [] },
    close: { type: Function, default: null },
    ifConditionToSet: { type: Function, default: () => {} }
  },
  data () {
    return {
      conditions: [
        {
          active: true,
          icon: 'alarm',
          name: 'Scheduled Time',
          displayName: this.$t('Scheduled Time'),
          description: this.$t('Ex. Every Day 5:00pm to 8:30pm'),
          geoFenceEnabled: true,
          view: 'scheduleTime'
        },
        {
          active: true,
          icon: 'important_devices',
          name: 'Device Status',
          displayName: this.$t('Device Status'),
          description: this.$t('Ex. When the door opens'),
          geoFenceEnabled: true,
          view: 'deviceStatus'
        },
        {
          active: true,
          icon: 'subscriptions',
          name: 'Scene',
          displayName: this.$t('Scene'),
          description: this.$t('Ex. When the scene is set to Away'),
          geoFenceEnabled: true,
          view: 'scene'
        },
        {
          active: true,
          icon: 'brightness_4',
          name: 'Weather',
          displayName: this.$t('Weather'),
          description: this.$t('Ex. When is raining'),
          geoFenceEnabled: true,
          view: 'weather'
        },
        {
          active: true,
          icon: 'verified_user',
          name: 'Security Mode',
          displayName: this.$t('Security Mode'),
          description: this.$t('Ex. When the alarm is On'),
          geoFenceEnabled: true,
          view: 'security'
        },
        {
          active: true,
          icon: 'thermostat',
          name: 'Indoor Temperature',
          displayName: this.$t('Indoor Temperature'),
          description: this.$t('Ex. If temp is below or above'),
          geoFenceEnabled: true,
          view: 'indoorTemperature'
        },
        {
          active: true,
          icon: 'location_on',
          name: 'User location',
          displayName: this.$t('User location'),
          description: this.$t('Ex. Im home'),
          geoFenceEnabled: this.checkGeofenceStatus(),
          view: 'userLocation'
        }
      ],
      weatherConditions: [
        { value: 'Sunny', name: this.$t('Sunny') },
        { value: 'Cloudy', name: this.$t('Cloudy') },
        { value: 'Raining', name: this.$t('Raining') },
        { value: 'Thunderstorm', name: this.$t('Thunderstorm') },
        { value: 'Snowing', name: this.$t('Snowing') }
      ],
      userLocationStatus: [
        { value: 'home', name: this.$t('Im home') },
        { value: 'away', name: this.$t('Im away') },
        { value: 'allAway', name: this.$t('Everyone is away') }
      ],
      tempRange: [
        { temp: '5 °C', value: 5 },
        { temp: '10 °C', value: 10 },
        { temp: '15 °C', value: 15 },
        { temp: '16 °C', value: 16 },
        { temp: '17 °C', value: 17 },
        { temp: '18 °C', value: 18 },
        { temp: '19 °C', value: 19 },
        { temp: '20 °C', value: 20 },
        { temp: '21 °C', value: 21 },
        { temp: '22 °C', value: 22 },
        { temp: '23 °C', value: 23 },
        { temp: '24 °C', value: 24 },
        { temp: '25 °C', value: 25 },
        { temp: '26 °C', value: 26 },
        { temp: '27 °C', value: 27 },
        { temp: '28 °C', value: 28 },
        { temp: '29 °C', value: 29 }
      ],
      closeOutline,
      addOutline,
      chevronBackOutline,
      checkmarkDoneOutline,
      selectedViewCondition: '1',
      scheduleTime: {
        conditionName: this.$t('Scheduled Time'),
        conditionNameBackEnd: 'Scheduled Time',
        description: '',
        description2: `${this.$t('Every')} `,
        icon: 'alarm',
        setting: {
          startTime: '15:00',
          endTime: '00:00',
          wholeDay: false,
          sunriseSunset: false,
          selectDays: []
        }
      },
      deviceStatus: {
        conditionName: this.$t('Device Status'),
        conditionNameBackEnd: 'Device Status',
        description: `${this.$t('If')} `,
        icon: 'important_devices',
        setting: []
      },
      weather: {
        conditionName: this.$t('Weather'),
        conditionNameBackEnd: 'Weather',
        description: `${this.$t('When its')} `,
        icon: 'brightness_4',
        setting: {
          weather: ''
        }
      },
      scene: {
        conditionName: this.$t('Scene'),
        conditionNameBackEnd: 'Scene',
        description: `${this.$t('If active scene is')} `,
        icon: 'subscriptions',
        setting: {
          scene: ''
        }
      },
      security: {
        conditionName: this.$t('Security Mode'),
        conditionNameBackEnd: 'Security Mode',
        description: `${this.$t('If alarm is')} `,
        icon: 'verified_user',
        setting: {
          security: false
        }
      },
      indoorTemp: {
        conditionName: this.$t('Indoor Temperature'),
        conditionNameBackEnd: 'Indoor Temperature',
        description: `${this.$t('If temp is')} `,
        icon: 'thermostat',
        setting: {
          temp: 19,
          isBelow: false,
          isAbove: false
        }
      },
      userLocation: {
        conditionName: this.$t('User location'),
        conditionNameBackEnd: 'User Location',
        description: `${this.$t('When')} `,
        userId: this.$store.state.userData[0].id,
        icon: 'location_on',
        setting: {
          where: 'home'
        }
      },
      ifCondition: {},
      errorMessage: ''
    }
  },
  computed: {
  },
  mounted () {},
  methods: {
    viewName (view) {
      const viewIndex = this.conditions.findIndex(item => item.view === view)
      return this.conditions[viewIndex].displayName
    },
    deviceRoom (id) {
      const roomId = Number(id)
      const rooms = this.$store.state.propertyRooms
      const found = rooms.find(element => element.id === roomId)
      return found ? found.roomName : '--'
    },
    doesAlreadyExists (conditionName) {
      const items = this.existingIfConditions
      let result = false
      items.forEach(item => {
        if (item.conditionName === conditionName) {
          result = true
        }
      }
      )
      return result
    },
    // Schedule time================================================================================================================
    addRemoveDay (day) {
      const dayIndex = this.scheduleTime.setting.selectDays.findIndex(item => item === day)
      if (dayIndex === -1) {
        this.scheduleTime.setting.selectDays.push(day)
        console.log(this.scheduleTime.setting.selectDays)
      } else {
        this.scheduleTime.setting.selectDays.splice(dayIndex, 1)
        console.log(this.scheduleTime.setting.selectDays)
      }
    },
    dayExists (day) {
      return this.scheduleTime.setting.selectDays.includes(day)
    },
    // DEVICE STATUS ================================================================================================================
    deviceStatusName (zigbeeModel) {
      if (zigbeeModel === 'SensorMotion') {
        return this.$t('If motion detected')
      }
      if (zigbeeModel === 'SensorDoorWindow') {
        return this.$t('If Door/Window opens')
      }
      if (zigbeeModel === 'SensorVibration') {
        return this.$t('If vibration detected')
      }
    },
    deviceExistsInCondition (device) {
      const deviceIndex = this.deviceStatus.setting.findIndex(item => item.ieee === device.ieee)
      if (deviceIndex === -1) {
        return false
      } else {
        return true
      }
    },
    addOrRemoveDeviceFromCondition (value, device) {
      if (value === 'true') {
        this.deviceStatus.setting.push(device)
        console.log(this.deviceStatus.setting)
      } else {
        const deviceIndex = this.deviceStatus.setting.findIndex(item => item.ieee === device.ieee)
        this.deviceStatus.setting.splice(deviceIndex, 1)
        console.log(this.deviceStatus.setting)
      }
    },
    // Weather condition =============================================================================================================
    selectWeather (value) {
      console.log(value)
      if (this.weather.setting.weather === value) {
        this.weather.setting.weather = ''
      } else {
        this.weather.setting.weather = value
      }
    },
    weatherSelected (value) {
      if (this.weather.setting.weather === '') {
        return false
      } else {
        if (this.weather.setting.weather === value) {
          return false
        } else {
          return true
        }
      }
    },
    // Scene Conditon ================================================================================================================
    sceneSelected (sceneName) {
      if (this.scene.setting.scene === '') {
        return false
      } else {
        if (this.scene.setting.scene === sceneName) {
          return false
        } else {
          return true
        }
      }
    },
    selectScene (sceneName) {
      if (this.scene.setting.scene === sceneName) {
        this.scene.setting.scene = ''
      } else {
        this.scene.setting.scene = sceneName
      }
    },
    // SAVE CONDITION ================================================================================================================
    saveCondition (condition) {
      if (condition === 'scheduleTime') {
        if (!this.scheduleTime.setting.selectDays.length) {
          this.error('Please selected days in which this should apply')
        } else {
          this.scheduleTime.setting.selectDays.forEach(item => {
            if (item) {
              this.scheduleTime.description2 = this.scheduleTime.description2 + item + ' '
            }
          })
          if (this.scheduleTime.setting.wholeDay) {
            this.scheduleTime.description = this.$t('From Midnight till Midnight')
          } else if (this.scheduleTime.setting.sunriseSunset) {
            this.scheduleTime.description = this.$t('From sunrise till sunset')
          } else {
            this.scheduleTime.description = `${this.$t('From')} ${this.scheduleTime.setting.startTime}  ${this.$t('Till')} ${this.scheduleTime.setting.endTime}`
          }
          this.ifCondition = this.scheduleTime
          this.ifConditionToSet(this.ifCondition)
          this.dismissModal()
        }
      } else if (condition === 'deviceStatus') {
        if (!this.deviceStatus.setting.length) {
          this.error('Please select device')
        } else {
          this.deviceStatus.setting.forEach(item => {
            if (item) {
              let conditionDescription
              if (item.zigbeeModel === 'SensorMotion') {
                conditionDescription = this.$t('Motion Detected')
              }
              if (item.zigbeeModel === 'SensorDoorWindow') {
                conditionDescription = this.$t('Door/Window Opened')
              }
              if (item.zigbeeModel === 'SensorVibration') {
                conditionDescription = this.$t('Vibration Detected')
              }
              this.deviceStatus.description = this.deviceStatus.description + conditionDescription + ' '
            }
          })
          this.ifCondition = this.deviceStatus
          this.ifConditionToSet(this.ifCondition)
          this.dismissModal()
        }
      } else if (condition === 'weather') {
        if (!this.weather.setting.weather.length) {
          this.error('Please select weather condition')
        } else {
          this.weather.description = this.weather.description + this.weather.setting.weather
          this.ifCondition = this.weather
          this.ifConditionToSet(this.ifCondition)
          this.dismissModal()
        }
      } else if (condition === 'scene') {
        if (!this.scene.setting.scene.length) {
          this.error('Please select scene')
        } else {
          this.scene.description = this.scene.description + ' ' + this.scene.setting.scene
          this.ifCondition = this.scene
          this.ifConditionToSet(this.ifCondition)
          this.dismissModal()
        }
      } else if (condition === 'security') {
        this.security.description = this.security.description + ' ' + (this.security.setting.security ? this.$t('ON') : this.$t('OFF'))
        this.ifCondition = this.security
        this.ifConditionToSet(this.ifCondition)
        this.dismissModal()
      } else if (condition === 'indoorTemperature') {
        const desc = this.indoorTemp.setting.isBelow === true ? this.$t('below') : this.$t('above')
        this.indoorTemp.description = this.indoorTemp.description + desc + ' ' + this.indoorTemp.setting.temp + '°C'
        this.ifCondition = this.indoorTemp
        this.ifConditionToSet(this.ifCondition)
        this.dismissModal()
      } else if (condition === 'userLocation') {
        this.userLocation.description = this.userLocation.description + this.mapLocationName(this.userLocation.setting.where)
        this.ifCondition = this.userLocation
        this.ifConditionToSet(this.ifCondition)
        this.dismissModal()
      }
    },
    error (error) {
      this.errorMessage = error
      setTimeout(() => { this.errorMessage = '' }, 2000)
    },
    mapLocationName (loc) {
      if (loc === 'home') {
        return this.$t('Im home')
      }
      if (loc === 'away') {
        return this.$t('Im away')
      }
      if (loc === 'allAway') {
        return this.$t('Everyone is away')
      }
    },
    checkGeofenceStatus () {
      return this.$store.state.userData[0].geofenceEnabled
    },
    async openSettingsAlert () {
      const alert = await alertController
        .create({
          cssClass: 'remove-device',
          header: 'Tillåt appen att komma åt din plats i inställningar',
          message: '',
          buttons: [
            {
              text: this.$t('Cancel'),
              role: 'cancel',
              cssClass: 'secondary'
            },
            {
              text: 'Gå till ställ in',
              handler: () => {
                this.openSettingsModal()
              }
            }
          ]
        })
      return alert.present()
    },
    openSettingsModal () {
      return modalController
        .create({
          component: settingsModal,
          cssClass: 'devices-modal',
          swipeToClose: true,
          showBackdrop: true,
          backdropDismiss: true,
          componentProps: {
            close: () => modalController.dismiss()
          }
        })
        .then(m => m.present())
    },
    dismissModal () {
      this.close()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
@import "~@/theme/_backgrounds";

.background {
    @include background-component-automation;
}

ion-list {
    background: none;
}

.h-65 {
   --min-height: 65px;
   @include bg-box-item;
}
.time-selector {
  max-width: 250px;
}
ion-datetime {
    font-size: 34px;
}
.day-button {
    height: 45px;
    width: 45px;
}
.day-inactive {
    --background: rgba(44, 48, 54, 0.6);
    color: white;
}
.day-active {
    --background: #3F424A;
    color: colors(primary, base);
    box-shadow: 0px 7px 11px rgba(0, 0, 0, 0.15), inset -5px -5px 250px rgba(255, 255, 255, 0.02);
    border: 1px solid colors(primary, base);
    border-radius: 5px;
}

</style>
