<template>
  <div class="background--modal" />
  <ion-header class="ion-no-border text-center">
    <ion-toolbar color="transparent">
      <ion-button
        slot="start"
        fill="clear"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="dismissModal()"
      >
        <img
          src="../../assets/images/arrow-go-back-line.svg"
        >
      </ion-button>
      <ion-title
        class="text--white text-18"
      >
        {{ $t('Edit Condition') }}
      </ion-title>
      <ion-button
        slot="end"
        fill="clear"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="saveCondition(selectedViewCondition)"
      >
        <ion-icon
          color="primary"
          size="large"
          :icon="checkmarkDoneOutline"
        />
      </ion-button>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <div v-if="selectedViewCondition === 'scheduleTime'">
      <div class="d-flex justify-content-center">
        <ion-item
          lines="none"
          class="text--white text--bold text--14"
          color="transparent"
        >
          <ion-text class="pr-5">
            {{ $t('Start Time') }}
          </ion-text>
        </ion-item>
        <ion-item
          lines="none"
          class="text--white text--bold text--14"
          color="transparent"
        >
          <ion-text class="pr-50">
            {{ $t('End Time') }}
          </ion-text>
        </ion-item>
      </div>
      <div class="d-flex justify-content-center">
        <ion-item
          :disabled="scheduleTime.setting.wholeDay || scheduleTime.setting.sunrise"
          lines="none"
          class="text--primary ion-no-padding text--bold"
          color="transparent"
        >
          <ion-datetime
            v-model="scheduleTime.setting.startTime"
            display-format="HH:mm"
          />
        </ion-item>
        <ion-item
          lines="none"
          :disabled="scheduleTime.setting.wholeDay || scheduleTime.setting.sunrise"
          class="text--white ion-no-padding text--bold"
          color="transparent"
        >
          <ion-datetime
            v-model="scheduleTime.setting.endTime"
            display-format="HH:mm"
          />
        </ion-item>
      </div>
      <ion-item
        lines="none"
        class="rounded-corners mt-30 device-item"
        color="transparent"
        :disabled="scheduleTime.setting.sunrise"
      >
        <ion-text
          slot="start"
          class="text--white text-16"
        >
          {{ $t('Whole day') }}
        </ion-text>
        <ion-toggle
          slot="end"
          v-model="scheduleTime.setting.wholeDay"
        />
      </ion-item>
      <ion-item
        lines="none"
        :disabled="scheduleTime.setting.wholeDay"
        color="transparent"
        class="rounded-corners mt-10 device-item"
      >
        <ion-text
          slot="start"
          class="text--white text-16"
        >
          {{ $t('From sunrise till sunset') }}
        </ion-text>
        <ion-toggle
          slot="end"
          v-model="scheduleTime.setting.sunrise"
        />
      </ion-item>
      <div class="mt-30 text-left">
        <ion-text class="text--uppercase text--white text--bold">
          {{ $t('select days') }}
        </ion-text>
        <ion-row
          lines="none"
          class="ion-no-padding mt-10"
          color="transparent"
        >
          <ion-button
            class="day-button"
            :class="dayExists('Mon') ? 'day-active' : 'day-inactive'"
            @click="addRemoveDay('Mon')"
          >
            {{ $t('Mon') }}
          </ion-button>
          <ion-button
            class="day-button"
            :class="dayExists('Tue') ? 'day-active' : 'day-inactive'"
            @click="addRemoveDay('Tue')"
          >
            {{ $t('Tue') }}
          </ion-button>
          <ion-button
            class="day-button"
            :class="dayExists('Wed') ? 'day-active' : 'day-inactive'"
            @click="addRemoveDay('Wed')"
          >
            {{ $t('Wed') }}
          </ion-button>
          <ion-button
            class="day-button"
            :class="dayExists('Thu') ? 'day-active' : 'day-inactive'"
            @click="addRemoveDay('Thu')"
          >
            {{ $t('Thu') }}
          </ion-button>
          <ion-button
            class="day-button"
            :class="dayExists('Fri') ? 'day-active' : 'day-inactive'"
            @click="addRemoveDay('Fri')"
          >
            {{ $t('Fri') }}
          </ion-button>
          <ion-button
            class="day-button"
            :class="dayExists('Sat') ? 'day-active' : 'day-inactive'"
            @click="addRemoveDay('Sat')"
          >
            {{ $t('Sat') }}
          </ion-button>
          <ion-button
            class="day-button"
            :class="dayExists('Sun') ? 'day-active' : 'day-inactive'"
            @click="addRemoveDay('Sun')"
          >
            {{ $t('Sun') }}
          </ion-button>
        </ion-row>
      </div>
      <div
        v-if="errorMessage"
        class="mt-8 text--primary"
      >
        {{ errorMessage }}
      </div>
    </div>
    <div v-else-if="selectedViewCondition === 'deviceStatus'">
      <ion-text v-if="devices.length === 0">
        {{ $t('No devices in your property') }}
      </ion-text>
      <div
        v-for="device in devices"
        :key="device"
      >
        <ion-item
          v-if="device.zigbeeModel === 'SensorMotion' || device.zigbeeModel === 'SensorDoorWindow'"
          :disabled="deviceStatus.setting.length && deviceStatus.setting[0].ieee !== device.ieee"
          lines="none"
          color="transparent"
          class="device-item rounded-corners mt-8"
        >
          <i
            slot="start"
            class="material-icons text--primary"
          >
            {{ device.zigbeeModel === 'SensorMotion' ? 'sensors' : 'sensor_door' }}
          </i>
          <ion-label>
            <ion-text class="text--white">
              {{ deviceStatusName(device.zigbeeModel) }}
            </ion-text><br>
            <ion-text class="text-14 text--gray">
              {{ $t('in') }} {{ deviceRoom(device.propertyRoomId) }}
            </ion-text>
          </ion-label>
          <ion-toggle
            slot="end"
            :value="deviceExistsInCondition(device) ? false : true"
            :checked="deviceExistsInCondition(device)"
            @ionChange="addOrRemoveDeviceFromCondition($event.target.value, device)"
          />
        </ion-item>
      </div>
      <div
        v-if="errorMessage"
        class="mt-8 text--primary"
      >
        {{ errorMessage }}
      </div>
    </div>
    <div v-else-if="selectedViewCondition === 'weather'">
      <div
        v-for="condition in weatherConditions"
        :key="condition"
      >
        <ion-item
          lines="none"
          :disabled="weatherSelected(condition.value)"
          color="transparent"
          class="rounded-corners mt-10 device-item"
        >
          <ion-text
            slot="start"
            class="text--white text-16"
          >
            {{ $t('If outside is') }} <span class="text--primary">{{ condition.name }}</span>
          </ion-text>
          <ion-toggle
            slot="end"
            :value="condition.value"
            :checked="isWeatherSelected(condition.value)"
            @ionChange="selectWeather($event.target.value)"
          />
        </ion-item>
      </div>
      <div
        v-if="errorMessage"
        class="mt-8 text--primary"
      >
        {{ errorMessage }}
      </div>
    </div>
    <div v-else-if="selectedViewCondition === 'scene'">
      <div
        v-for="oneScene in scenes"
        :key="oneScene"
      >
        <ion-item
          lines="none"
          :disabled="sceneSelected(oneScene.sceneName)"
          color="transparent"
          class="rounded-corners mt-10 device-item"
        >
          <ion-text
            slot="start"
            class="text--white text-16"
          >
            {{ $t('If active scene is') }} <span class="text--primary">{{ oneScene.sceneName }}</span>
          </ion-text>
          <ion-toggle
            slot="end"
            :value="oneScene.sceneName"
            :checked="isSceneSelected(oneScene.sceneName)"
            @ionChange="selectScene($event.target.value)"
          />
        </ion-item>
      </div>
      <div
        v-if="errorMessage"
        class="mt-8 text--primary"
      >
        {{ errorMessage }}
      </div>
    </div>
    <div v-else-if="selectedViewCondition === 'security'">
      <ion-item
        lines="none"
        color="transparent"
        class="rounded-corners mt-10 device-item"
      >
        <ion-text
          slot="start"
          class="text--white text-16"
        >
          {{ $t('If alarm is') }} <span class="text--primary">{{ security.setting.security ? $t('ON') : $t('OFF') }}</span>
        </ion-text>
        <ion-toggle
          slot="end"
          v-model="security.setting.security"
        />
      </ion-item>
    </div>
    <div v-else-if="selectedViewCondition === 'indoorTemperature'">
      <ion-item
        lines="none"
        color="transparent"
        class="rounded-corners mt-10 h-65 text--white"
      >
        <ion-label>
          <span class="text--white">{{ $t('Temperature') }}</span>
        </ion-label>
        <ion-select
          slot="end"
          v-model="indoorTemp.setting.temp"
          class="text--primary text--bold"
          placeholder="Select One"
        >
          <ion-select-option
            v-for="{ temp, value } in tempRange"
            :key="temp"
            :value="value"
          >
            {{ temp }}
          </ion-select-option>
        </ion-select>
        <i
          slot="end"
          class="mdi mdi-chevron-down text--white"
        />
      </ion-item>
      <ion-item
        lines="none"
        :disabled="indoorTemp.setting.isBelow"
        color="transparent"
        class="rounded-corners mt-10 device-item text--white"
      >
        <ion-label>
          <span class="text--white">{{ $t('Is above') }}</span>
        </ion-label>
        <ion-toggle v-model="indoorTemp.setting.isAbove" />
      </ion-item>
      <ion-item
        lines="none"
        :disabled="indoorTemp.setting.isAbove"
        color="transparent"
        class="rounded-corners mt-10 device-item text--white"
      >
        <ion-label>
          <span class="text--white">{{ $t('Is below') }}</span>
        </ion-label>
        <ion-toggle v-model="indoorTemp.setting.isBelow" />
      </ion-item>
      <div
        v-if="errorMessage"
        class="mt-8 text--primary"
      >
        {{ errorMessage }}
      </div>
    </div>
    <div v-else-if="selectedViewCondition === 'userLocation'">
      <ion-item
        lines="none"
        class="rounded-corners mt-10 h-65 text--white"
      >
        <ion-label>
          <span class="text--white">{{ $t('When') }}</span>
        </ion-label>
        <ion-select
          slot="end"
          v-model="userLocation.setting.where"
          class="text--primary text--bold"
          placeholder="Select One"
        >
          <ion-select-option
            v-for="{ name, value } in userLocationStatus"
            :key="name"
            :value="value"
          >
            {{ name }}
          </ion-select-option>
        </ion-select>
        <i
          slot="end"
          class="mdi mdi-chevron-down text--white"
        />
      </ion-item>
      <div
        v-if="errorMessage"
        class="mt-8 text--primary"
      >
        {{ errorMessage }}
      </div>
    </div>
  </ion-content>
</template>

<script>
import { closeOutline, addOutline, chevronBackOutline, checkmarkDoneOutline } from 'ionicons/icons'

export default {
  props: {
    devices: { type: Array, default: () => [] },
    scenes: { type: Array, default: () => [] },
    ifCondition: { type: Object, default: () => {} },
    close: { type: Function, default: null },
    editedConditionToSave: { type: Function, default: () => {} }
  },
  data () {
    return {
      weatherConditions: [
        { value: 'Sunny', name: this.$t('Sunny') },
        { value: 'Cloudy', name: this.$t('Cloudy') },
        { value: 'Raining', name: this.$t('Raining') },
        { value: 'Thunderstorm', name: this.$t('Thunderstorm') },
        { value: 'Snowing', name: this.$t('Snowing') }
      ],
      closeOutline,
      addOutline,
      chevronBackOutline,
      checkmarkDoneOutline,
      selectedViewCondition: '1',
      scheduleTime: {
        conditionName: 'Scheduled Time',
        description: '',
        description2: 'Every ',
        icon: 'alarm',
        setting: {
          startTime: '15:00',
          endTime: '00:00',
          wholeDay: false,
          sunriseSunset: false,
          selectDays: []
        }
      },
      deviceStatus: {
        conditionName: 'Device Status',
        description: 'If ',
        icon: 'important_devices',
        setting: []
      },
      weather: {
        conditionName: 'Weather',
        description: 'When it\'s ',
        icon: 'brightness_4',
        setting: {
          weather: ''
        }
      },
      scene: {
        conditionName: 'Scene',
        description: 'If active scene is ',
        icon: 'subscriptions',
        setting: {
          scene: ''
        }
      },
      security: {
        conditionName: 'Security Mode',
        description: 'If alarm is ',
        icon: 'verified_user',
        setting: {
          security: false
        }
      },
      indoorTemp: {
        conditionName: this.$t('Indoor Temperature'),
        conditionNameBackEnd: 'Indoor Temperature',
        description: `${this.$t('If temp is')} `,
        icon: 'thermostat',
        setting: {
          temp: 19,
          isBelow: false,
          isAbove: false
        }
      },
      userLocation: {
        conditionName: this.$t('User location'),
        conditionNameBackEnd: 'User Location',
        description: `${this.$t('When')} `,
        userId: this.$store.state.userData[0].id,
        icon: 'location_on',
        setting: {
          where: 'home'
        }
      },
      errorMessage: '',
      tempRange: [
        { temp: '5 °C', value: 5 },
        { temp: '10 °C', value: 10 },
        { temp: '15 °C', value: 15 },
        { temp: '16 °C', value: 16 },
        { temp: '17 °C', value: 17 },
        { temp: '18 °C', value: 18 },
        { temp: '19 °C', value: 19 },
        { temp: '20 °C', value: 20 },
        { temp: '21 °C', value: 21 },
        { temp: '22 °C', value: 22 },
        { temp: '23 °C', value: 23 },
        { temp: '24 °C', value: 24 },
        { temp: '25 °C', value: 25 },
        { temp: '26 °C', value: 26 },
        { temp: '27 °C', value: 27 },
        { temp: '28 °C', value: 28 },
        { temp: '29 °C', value: 29 }
      ],
      userLocationStatus: [
        { value: 'home', name: this.$t('Im home') },
        { value: 'away', name: this.$t('Im away') },
        { value: 'allAway', name: this.$t('Everyone is away') }
      ]
    }
  },
  computed: {
  },
  mounted () {},
  created () {
    console.log(this.ifCondition)
    if (this.ifCondition.conditionNameBackEnd === 'Scheduled Time') {
      this.scheduleTime = this.ifCondition
      this.selectedViewCondition = 'scheduleTime'
    } else if (this.ifCondition.conditionNameBackEnd === 'Device Status') {
      this.deviceStatus = this.ifCondition
      this.selectedViewCondition = 'deviceStatus'
    } else if (this.ifCondition.conditionNameBackEnd === 'Weather') {
      this.weather = this.ifCondition
      this.selectedViewCondition = 'weather'
    } else if (this.ifCondition.conditionNameBackEnd === 'Scene') {
      this.scene = this.ifCondition
      this.selectedViewCondition = 'scene'
    } else if (this.ifCondition.conditionNameBackEnd === 'Security Mode') {
      this.security = this.ifCondition
      this.selectedViewCondition = 'security'
    } else if (this.ifCondition.conditionNameBackEnd === 'Indoor Temperature') {
      this.indoorTemp = this.ifCondition
      this.selectedViewCondition = 'indoorTemperature'
    } else if (this.ifCondition.conditionNameBackEnd === 'User Location') {
      this.userLocation = this.ifCondition
      this.selectedViewCondition = 'userLocation'
    }
  },
  methods: {
    viewName (view) {
      const viewIndex = this.conditions.findIndex(item => item.view === view)
      return this.conditions[viewIndex].name
    },
    deviceRoom (id) {
      const roomId = Number(id)
      const rooms = this.$store.state.propertyRooms
      const found = rooms.find(element => element.id === roomId)
      return found ? found.roomName : '--'
    },
    doesAlreadyExists (conditionName) {
      const items = this.existingIfConditions
      let result = false
      items.forEach(item => {
        if (item.conditionName === conditionName) {
          result = true
        }
      }
      )
      return result
    },
    // Schedule time================================================================================================================
    addRemoveDay (day) {
      const dayIndex = this.scheduleTime.setting.selectDays.findIndex(item => item === day)
      if (dayIndex === -1) {
        this.scheduleTime.setting.selectDays.push(day)
        console.log(this.scheduleTime.setting.selectDays)
      } else {
        this.scheduleTime.setting.selectDays.splice(dayIndex, 1)
        console.log(this.scheduleTime.setting.selectDays)
      }
    },
    dayExists (day) {
      return this.scheduleTime.setting.selectDays.includes(day)
    },
    // DEVICE STATUS ================================================================================================================
    deviceStatusName (zigbeeModel) {
      if (zigbeeModel === 'SensorMotion') {
        return this.$t('If motion detected')
      }
      if (zigbeeModel === 'SensorDoorWindow') {
        return this.$t('If Door/Window opens')
      }
    },
    deviceExistsInCondition (device) {
      const deviceIndex = this.deviceStatus.setting.findIndex(item => item.ieee === device.ieee)
      if (deviceIndex === -1) {
        return false
      } else {
        return true
      }
    },
    addOrRemoveDeviceFromCondition (value, device) {
      if (value === 'true') {
        this.deviceStatus.setting.push(device)
        console.log(this.deviceStatus.setting)
      } else {
        const deviceIndex = this.deviceStatus.setting.findIndex(item => item.ieee === device.ieee)
        this.deviceStatus.setting.splice(deviceIndex, 1)
        console.log(this.deviceStatus.setting)
      }
    },
    // Weather condition =============================================================================================================
    selectWeather (value) {
      console.log(this.weather)
      if (this.weather.setting.weather === value) {
        this.weather.setting.weather = ''
      } else {
        this.weather.setting.weather = value
      }
      console.log(this.weather)
    },
    isWeatherSelected (value) {
      if (this.weather.setting.weather === value) {
        return true
      } else {
        return false
      }
    },
    weatherSelected (value) {
      if (this.weather.setting.weather === '') {
        return false
      } else {
        if (this.weather.setting.weather === value) {
          return false
        } else {
          return true
        }
      }
    },
    // Scene Conditon ================================================================================================================
    sceneSelected (sceneName) {
      if (this.scene.setting.scene === '') {
        return false
      } else {
        if (this.scene.setting.scene === sceneName) {
          return false
        } else {
          return true
        }
      }
    },
    isSceneSelected (sceneName) {
      if (this.scene.setting.scene === sceneName) {
        return true
      } else {
        return false
      }
    },
    selectScene (sceneName) {
      if (this.scene.setting.scene === sceneName) {
        this.scene.setting.scene = ''
      } else {
        this.scene.setting.scene = sceneName
      }
    },
    // SAVE CONDITION ================================================================================================================
    saveCondition (condition) {
      if (condition === 'scheduleTime') {
        if (!this.scheduleTime.setting.selectDays.length) {
          this.error('Please selected days in which this should apply')
        } else {
          this.scheduleTime.description2 = `${this.$t('Every')} `
          this.scheduleTime.description = ''
          this.scheduleTime.setting.selectDays.forEach(item => {
            if (item) {
              this.scheduleTime.description2 = this.scheduleTime.description2 + item + ' '
            }
          })
          if (this.scheduleTime.setting.wholeDay) {
            this.scheduleTime.description = this.$t('From Midnight till Midnight')
          } else if (this.scheduleTime.setting.sunriseSunset) {
            this.scheduleTime.description = this.$t('From sunrise till sunset')
          } else {
            this.scheduleTime.description = `${this.$t('From')} ${this.scheduleTime.setting.startTime}  ${this.$t('Till')} ${this.scheduleTime.setting.endTime}`
          }
          this.editedConditionToSave(this.scheduleTime)
          this.dismissModal()
        }
      } else if (condition === 'deviceStatus') {
        if (!this.deviceStatus.setting.length) {
          this.error('Please select device')
        } else {
          this.deviceStatus.description = `${this.$t('If')} `
          this.deviceStatus.setting.forEach(item => {
            if (item) {
              let conditionDescription
              if (item.zigbeeModel === 'SensorMotion') {
                conditionDescription = this.$t('Motion Detected')
              }
              if (item.zigbeeModel === 'SensorDoorWindow') {
                conditionDescription = this.$t('Door/Window Opened')
              }
              this.deviceStatus.description = this.deviceStatus.description + conditionDescription + ' '
            }
          })
          this.editedConditionToSave(this.deviceStatus)
          this.dismissModal()
        }
      } else if (condition === 'weather') {
        if (!this.weather.setting.weather.length) {
          this.error('Please select weather preset')
        } else {
          this.weather.description = `${this.$t('When its')} `
          this.weather.description = this.weather.description + this.weather.setting.weather
          this.editedConditionToSave(this.weather)
          this.dismissModal()
        }
      } else if (condition === 'scene') {
        if (!this.scene.setting.scene.length) {
          this.error('Please select scene')
        } else {
          this.scene.description = `${this.$t('If active scene is')} `
          this.scene.description = this.scene.description + ' ' + this.scene.setting.scene
          this.editedConditionToSave(this.scene)
          this.dismissModal()
        }
      } else if (condition === 'security') {
        this.security.description = `${this.$t('If alarm is')} `
        this.security.description = this.security.description + ' ' + (this.security.setting.security ? 'ON' : 'OFF')
        this.editedConditionToSave(this.security)
        this.dismissModal()
      } else if (condition === 'indoorTemperature') {
        this.indoorTemp.description = `${this.$t('If temp is')} `
        const desc = this.indoorTemp.setting.isBelow === true ? this.$t('below') : this.$t('above')
        this.indoorTemp.description = this.indoorTemp.description + desc + ' ' + this.indoorTemp.setting.temp + '°C'
        this.editedConditionToSave(this.indoorTemp)
        this.dismissModal()
      } else if (condition === 'userLocation') {
        this.userLocation.description = `${this.$t('When')} `
        this.userLocation.description = this.userLocation.description + this.mapLocationName(this.userLocation.setting.where)
        this.editedConditionToSave(this.userLocation)
        this.dismissModal()
      }
    },
    mapLocationName (loc) {
      if (loc === 'home') {
        return this.$t('Im home')
      }
      if (loc === 'away') {
        return this.$t('Im away')
      }
      if (loc === 'allAway') {
        return this.$t('Everyone is away')
      }
    },
    error (error) {
      this.errorMessage = error
      setTimeout(() => { this.errorMessage = '' }, 2000)
    },
    dismissModal () {
      this.close()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
@import "~@/theme/_backgrounds";

.background {
    @include background-component-automation;
}

ion-list {
    background: none;
}

.h-65 {
   --min-height: 65px;
   @include bg-box-item;
}
.time-selector {
  max-width: 250px;
}
ion-datetime {
    font-size: 34px;
}
.day-button {
    height: 45px;
    width: 45px;
}
.day-inactive {
    --background: rgba(44, 48, 54, 0.6);
    color: white;
}
.day-active {
    --background: #3F424A;
    color: colors(primary, base);
    box-shadow: 0px 7px 11px rgba(0, 0, 0, 0.15), inset -5px -5px 250px rgba(255, 255, 255, 0.02);
    border: 1px solid colors(primary, base);
    border-radius: 5px;
}

</style>
