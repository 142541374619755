<template>
  <ion-header
    class="ion-no-border"
  >
    <ion-toolbar
      color="transparent"
    >
      <ion-title
        class="text-18 text--white"
      >
        {{ $t('Set timer') }}
      </ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content
    :scroll-y="false"
  >
    <ion-text class="text--white">
      <!-- {{ description }} -->
      <!-- eslint-disable-next-line vue/no-v-html -->
      <span v-html="description" />
    </ion-text>
    <ion-item
      class="text--primary text--bold"
      color="transparent"
      lines="none"
    >
      <ion-select
        v-model="setTimerTo"
        style="margin: 0 auto;"
      >
        <ion-select-option
          value="forever"
        >
          {{ $t('Forever') }}
        </ion-select-option>
        <ion-select-option value="1 min">
          1 min
        </ion-select-option>
        <ion-select-option value="3 min">
          3 min
        </ion-select-option>
        <ion-select-option value="5 min">
          5 min
        </ion-select-option>
        <ion-select-option value="10 min">
          10 min
        </ion-select-option>
        <ion-select-option value="20 min">
          20 min
        </ion-select-option>
        <ion-select-option value="30 min">
          30 min
        </ion-select-option>
        <ion-select-option value="60 min">
          1 hr
        </ion-select-option>
      </ion-select>
      <i class="mdi mdi-chevron-down" />
    </ion-item>
  </ion-content>
  <ion-footer class="footer">
    <ion-button
      @click="applyTimer"
    >
      <ion-label>
        {{ $t('save') }}
      </ion-label>
    </ion-button>
    <ion-button
      fill="clear"
      color="transparent"
      @click="dismissPopover"
    >
      <ion-label color="primary">
        {{ $t('close') }}
      </ion-label>
    </ion-button>
  </ion-footer>
</template>

<script>
import { IonContent } from '@ionic/vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Popover',
  components: { IonContent },
  props: {
    device: { type: Object, default: null },
    close: { type: Function, default: null },
    timerToSet: { type: Function, default: null }
  },
  data () {
    return {
      setTimerTo: 'forever',
      description: ''
    }
  },
  computed: {
  },
  created () {
    const device = this.device
    this.description = `${this.$t('This device will be')}<span class="text--primary text--bold text--uppercase">${device.switchgear === 'on' ? this.$t('On') : this.$t('Off')}</span> ${this.$t('for')}`
  },
  methods: {
    applyTimer () {
      const timer = {
        setTimerTo: this.setTimerTo,
        deviceId: this.device.id
      }
      this.timerToSet(timer)
      this.dismissPopover()
    },
    dismissPopover () {
      this.close()
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
@import "~@/theme/_backgrounds";
</style>
